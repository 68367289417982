import React from 'react';

import InputField, { InputFieldProps } from 'components/InputField';

import { testEmail } from 'services/regex';

export interface OwnProps extends Omit<InputFieldProps, 'type' | 'label' | 'mask' | 'rules'> {}

export interface Props extends OwnProps {}

const EmailField = (props: Props) => (
  <InputField
    {...props}
    label="Email"
    type="email"
    rules={{
      required: 'Veuillez saisir votre email',
      validate: (value) => testEmail(value) || 'Veuillez saisir une adresse email valide',
    }}
  />
);

export default EmailField;
