import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './LoyerImpayeQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitLoyerImpayeQuote(action: ReturnType<typeof actions.submitLoyerImpayeQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<LoyerImpayeQuote> = yield call(
      request.post<FormLoyerImpayeValues>('loyerImpaye/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitLoyerImpayeQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formLoyerImpaye(quote.id)));
  } catch (error: any) {
    yield put(actions.submitLoyerImpayeQuoteError(error));
  }
}

function* fetchLoyerImpayeQuote(action: ReturnType<typeof actions.fetchLoyerImpayeQuote>) {
  try {
    const loyerImpayeQuoteId = action.payload;

    const quote: WithId<LoyerImpayeQuote> = yield call(
      firestore.get(`quotes/${loyerImpayeQuoteId}`),
    );
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchLoyerImpayeQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchLoyerImpayeQuoteError(error));
  }
}

function* updateLoyerImpayeQuote(action: ReturnType<typeof actions.updateLoyerImpayeQuote>) {
  try {
    const loyerImpayeQuote = action.payload;

    yield put(actions.updateLoyerImpayeQuoteSuccess(loyerImpayeQuote));
  } catch (error: any) {
    yield put(actions.updateLoyerImpayeQuoteError(error));
  }
}

function* saveLoyerImpayeQuote(action: ReturnType<typeof actions.saveLoyerImpayeQuote>) {
  try {
    const { loyerImpayeQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`loyerImpaye/devis/${loyerImpayeQuoteId}`, tarif));

    yield put(actions.saveLoyerImpayeQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveLoyerImpayeQuoteError(error));
  }
}

export default function* loyerImpayeQuoteSaga() {
  yield takeEvery(actions.submitLoyerImpayeQuote, submitLoyerImpayeQuote);
  yield takeEvery(actions.fetchLoyerImpayeQuote, fetchLoyerImpayeQuote);
  yield takeEvery(actions.updateLoyerImpayeQuote, updateLoyerImpayeQuote);
  yield takeEvery(actions.saveLoyerImpayeQuote, saveLoyerImpayeQuote);
}
