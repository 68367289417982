import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './EmprunteurQuote.actions';

const initialState: EmprunteurQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<EmprunteurQuoteState>(initialState, {
  [actions.submitEmprunteurQuote.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.submitEmprunteurQuote>,
  ): EmprunteurQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitEmprunteurQuoteError.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.submitEmprunteurQuoteError>,
  ): EmprunteurQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitEmprunteurQuoteSuccess.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.submitEmprunteurQuoteSuccess>,
  ): EmprunteurQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchEmprunteurQuote.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.fetchEmprunteurQuote>,
  ): EmprunteurQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchEmprunteurQuoteError.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.fetchEmprunteurQuoteError>,
  ): EmprunteurQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchEmprunteurQuotesSuccess.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.fetchEmprunteurQuotesSuccess>,
  ): EmprunteurQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<EmprunteurQuote> }>(
      (acc, emprunteurQuote) => ({
        ...acc,
        [emprunteurQuote.id]: emprunteurQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((emprunteurQuote) => emprunteurQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateEmprunteurQuote.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.updateEmprunteurQuote>,
  ): EmprunteurQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateEmprunteurQuoteError.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.updateEmprunteurQuoteError>,
  ): EmprunteurQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateEmprunteurQuoteSuccess.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.updateEmprunteurQuoteSuccess>,
  ): EmprunteurQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveEmprunteurQuote.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.saveEmprunteurQuote>,
  ): EmprunteurQuoteState => ({
    ...state,
    isUpdating: action.payload.emprunteurQuoteId,
  }),
  [actions.saveEmprunteurQuoteError.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.saveEmprunteurQuoteError>,
  ): EmprunteurQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveEmprunteurQuoteSuccess.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.saveEmprunteurQuoteSuccess>,
  ): EmprunteurQuoteState => ({
    ...state,
    isUpdating: '',
  }),
  [actions.subscribeEmprunteurQuote.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.subscribeEmprunteurQuote>,
  ): EmprunteurQuoteState => ({
    ...state,
    isUpdating: action.payload.emprunteurQuoteId,
  }),
  [actions.subscribeEmprunteurQuoteError.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.subscribeEmprunteurQuoteError>,
  ): EmprunteurQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
      subscription: {
        success: false,
        displayed: true,
      },
    };
  },
  [actions.subscribeEmprunteurQuoteSuccess.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.subscribeEmprunteurQuoteSuccess>,
  ): EmprunteurQuoteState => ({
    ...state,
    isUpdating: '',
    subscription: { ...action.payload, displayed: true },
  }),
  [actions.setSubscriptionStatusDisplayed.type]: (
    state: EmprunteurQuoteState,
    action: ReturnType<typeof actions.setSubscriptionStatusDisplayed>,
  ): EmprunteurQuoteState => ({
    ...state,
    subscription: {
      ...state.subscription,
      success: state.subscription?.success || false,
      displayed: action.payload,
    },
  }),
});
