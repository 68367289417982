import { createAction } from '@reduxjs/toolkit';

export const submitEmprunteurQuote = createAction<FormEmprunteurValues>(
  'CREATE_EMPRUNTEUR_QUOTE_REQUEST',
);
export const submitEmprunteurQuoteSuccess = createAction<WithId<EmprunteurQuote>>(
  'CREATE_EMPRUNTEUR_QUOTE_SUCCESS',
);
export const submitEmprunteurQuoteError = createAction<Error>('CREATE_EMPRUNTEUR_QUOTE_ERROR');

export const fetchEmprunteurQuote = createAction<string>('FETCH_EMPRUNTEUR_QUOTE_REQUEST');
export const fetchEmprunteurQuoteError = createAction<Error>('FETCH_EMPRUNTEUR_QUOTE_ERROR');
export const fetchEmprunteurQuotesSuccess = createAction<WithId<EmprunteurQuote>[]>(
  'FETCH_EMPRUNTEUR_QUOTES_SUCCESS',
);

export const updateEmprunteurQuote = createAction<WithId<EmprunteurQuote>>(
  'UPDATE_EMPRUNTEUR_QUOTE_REQUEST',
);
export const updateEmprunteurQuoteSuccess = createAction<WithId<EmprunteurQuote>>(
  'UPDATE_EMPRUNTEUR_QUOTE_SUCCESS',
);
export const updateEmprunteurQuoteError = createAction<Error>('UPDATE_EMPRUNTEUR_QUOTE_ERROR');

export const saveEmprunteurQuote = createAction(
  'SAVE_EMPRUNTEUR_QUOTE_REQUEST',
  (emprunteurQuoteId: string, tarif: Tarif) => ({ payload: { emprunteurQuoteId, tarif } }),
);
export const saveEmprunteurQuoteSuccess = createAction('SAVE_EMPRUNTEUR_QUOTE_SUCCESS');
export const saveEmprunteurQuoteError = createAction<Error>('SAVE_EMPRUNTEUR_QUOTE_ERROR');

export const subscribeEmprunteurQuote = createAction(
  'SUBSCRIBE_EMPRUNTEUR_QUOTE_REQUEST',
  (emprunteurQuoteId: string, tarif: Tarif) => ({ payload: { emprunteurQuoteId, tarif } }),
);
export const subscribeEmprunteurQuoteSuccess = createAction<SubscriptionStatus>(
  'SUBSCRIBE_EMPRUNTEUR_QUOTE_SUCCESS',
);
export const subscribeEmprunteurQuoteError = createAction<Error>(
  'SUBSCRIBE_EMPRUNTEUR_QUOTE_ERROR',
);

export const setSubscriptionStatusDisplayed = createAction<boolean>(
  'SET_EMPRUNTEUR_QUOTE_SUBSCRIPTION_DISPLAYED',
);
