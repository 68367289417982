import styled from '@emotion/styled';

import InputField from 'components/InputField';

import { desktopMediaQuery } from 'services/theme';

export const StyledInputField = styled(InputField)`
  @media ${desktopMediaQuery} {
    max-width: 220px;

    && {
      flex-grow: 0;
    }
  }
`;
