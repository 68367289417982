import compose from 'recompose/compose';
import { connect, DefaultRootState } from 'react-redux';

import BandePartenaires, { Props, OwnProps } from './BandePartenaires.component';

import { fetchPartenaires } from 'modules/Partenaire/Partenaire.actions';
import { selectLogoUrls } from 'modules/Partenaire/Partenaire.selectors';

export const mapStateToProps = (state: DefaultRootState) => ({
  logoUrls: selectLogoUrls(state),
});

export const mapDispatchToProps = {
  fetchPartenaires,
};

export default compose<Props, OwnProps>(connect(mapStateToProps, mapDispatchToProps))(
  BandePartenaires,
);
