import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './HabitationQuote.actions';

const initialState: HabitationQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<HabitationQuoteState>(initialState, {
  [actions.submitHabitationQuote.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.submitHabitationQuote>,
  ): HabitationQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitHabitationQuoteError.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.submitHabitationQuoteError>,
  ): HabitationQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitHabitationQuoteSuccess.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.submitHabitationQuoteSuccess>,
  ): HabitationQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchHabitationQuote.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.fetchHabitationQuote>,
  ): HabitationQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchHabitationQuoteError.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.fetchHabitationQuoteError>,
  ): HabitationQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchHabitationQuotesSuccess.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.fetchHabitationQuotesSuccess>,
  ): HabitationQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<HabitationQuote> }>(
      (acc, habitationQuote) => ({
        ...acc,
        [habitationQuote.id]: habitationQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((habitationQuote) => habitationQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateHabitationQuote.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.updateHabitationQuote>,
  ): HabitationQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateHabitationQuoteError.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.updateHabitationQuoteError>,
  ): HabitationQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateHabitationQuoteSuccess.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.updateHabitationQuoteSuccess>,
  ): HabitationQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveHabitationQuote.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.saveHabitationQuote>,
  ): HabitationQuoteState => ({
    ...state,
    isUpdating: action.payload.habitationQuoteId,
  }),
  [actions.saveHabitationQuoteError.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.saveHabitationQuoteError>,
  ): HabitationQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveHabitationQuoteSuccess.type]: (
    state: HabitationQuoteState,
    action: ReturnType<typeof actions.saveHabitationQuoteSuccess>,
  ): HabitationQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
