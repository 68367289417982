import React from 'react';
import { LinkProps } from 'react-router-dom';
import { Optional } from 'utility-types';

import { StyledLink, StyledAnchor } from './Link.style';

export interface OwnProps extends Optional<LinkProps, 'to'> {
  href?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  muiButton?: boolean;
}

export interface Props extends OwnProps {}

const Link = ({ to, href, className, children, muiButton = false, ...props }: Props) => {
  if (!!muiButton) className = className + ' MuiButtonBase-root MuiButton-root';

  if (!!to)
    return (
      <StyledLink className={className} to={to}>
        {children}
      </StyledLink>
    );

  return (
    <StyledAnchor {...props} className={className} href={href}>
      {children}
    </StyledAnchor>
  );
};

export default Link;
