import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './LoyerImpayeQuote.actions';

const initialState: LoyerImpayeQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<LoyerImpayeQuoteState>(initialState, {
  [actions.submitLoyerImpayeQuote.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.submitLoyerImpayeQuote>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitLoyerImpayeQuoteError.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.submitLoyerImpayeQuoteError>,
  ): LoyerImpayeQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitLoyerImpayeQuoteSuccess.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.submitLoyerImpayeQuoteSuccess>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchLoyerImpayeQuote.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.fetchLoyerImpayeQuote>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchLoyerImpayeQuoteError.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.fetchLoyerImpayeQuoteError>,
  ): LoyerImpayeQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchLoyerImpayeQuotesSuccess.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.fetchLoyerImpayeQuotesSuccess>,
  ): LoyerImpayeQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<LoyerImpayeQuote> }>(
      (acc, loyerImpayeQuote) => ({
        ...acc,
        [loyerImpayeQuote.id]: loyerImpayeQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((loyerImpayeQuote) => loyerImpayeQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateLoyerImpayeQuote.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.updateLoyerImpayeQuote>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateLoyerImpayeQuoteError.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.updateLoyerImpayeQuoteError>,
  ): LoyerImpayeQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateLoyerImpayeQuoteSuccess.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.updateLoyerImpayeQuoteSuccess>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveLoyerImpayeQuote.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.saveLoyerImpayeQuote>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    isUpdating: action.payload.loyerImpayeQuoteId,
  }),
  [actions.saveLoyerImpayeQuoteError.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.saveLoyerImpayeQuoteError>,
  ): LoyerImpayeQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveLoyerImpayeQuoteSuccess.type]: (
    state: LoyerImpayeQuoteState,
    action: ReturnType<typeof actions.saveLoyerImpayeQuoteSuccess>,
  ): LoyerImpayeQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
