import { createAction } from '@reduxjs/toolkit';

export const submitTelesurveillanceQuote = createAction<FormTelesurveillanceValues>(
  'CREATE_TELESURVEILLANCE_QUOTE_REQUEST',
);
export const submitTelesurveillanceQuoteSuccess = createAction<WithId<TelesurveillanceQuote>>(
  'CREATE_TELESURVEILLANCE_QUOTE_SUCCESS',
);
export const submitTelesurveillanceQuoteError = createAction<Error>(
  'CREATE_TELESURVEILLANCE_QUOTE_ERROR',
);

export const fetchTelesurveillanceQuote = createAction<string>(
  'FETCH_TELESURVEILLANCE_QUOTE_REQUEST',
);
export const fetchTelesurveillanceQuoteError = createAction<Error>(
  'FETCH_TELESURVEILLANCE_QUOTE_ERROR',
);
export const fetchTelesurveillanceQuotesSuccess = createAction<WithId<TelesurveillanceQuote>[]>(
  'FETCH_TELESURVEILLANCE_QUOTES_SUCCESS',
);

export const updateTelesurveillanceQuote = createAction<WithId<TelesurveillanceQuote>>(
  'UPDATE_TELESURVEILLANCE_QUOTE_REQUEST',
);
export const updateTelesurveillanceQuoteSuccess = createAction<WithId<TelesurveillanceQuote>>(
  'UPDATE_TELESURVEILLANCE_QUOTE_SUCCESS',
);
export const updateTelesurveillanceQuoteError = createAction<Error>(
  'UPDATE_TELESURVEILLANCE_QUOTE_ERROR',
);

export const saveTelesurveillanceQuote = createAction(
  'SAVE_TELESURVEILLANCE_QUOTE_REQUEST',
  (telesurveillanceQuoteId: string, tarif: Tarif) => ({
    payload: { telesurveillanceQuoteId, tarif },
  }),
);
export const saveTelesurveillanceQuoteSuccess = createAction('SAVE_TELESURVEILLANCE_QUOTE_SUCCESS');
export const saveTelesurveillanceQuoteError = createAction<Error>(
  'SAVE_TELESURVEILLANCE_QUOTE_ERROR',
);
