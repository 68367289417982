import React from 'react';

import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Link from 'components/Link';
import {
  StyledColoredContainer,
  StyledLogo,
  StyledButtonGroup,
  StyledTypography,
  StyledFab,
  StyledLink,
  StyledNavbarContainer,
} from './Header.style';

import routes from 'App.routes';

import { useNavigate } from 'services/navigation';
import { useIsMobile } from 'services/theme';

import { mapStateToProps, mapDispatchToProps } from './Header.container';

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export interface OwnProps {}

export interface Props extends StateProps, DispatchProps, OwnProps {}

const Header = (props: Props) => {
  const isMobile = useIsMobile();

  const navigateHome = useNavigate(routes.home());

  return (
    <>
      <StyledColoredContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'space-between'}
          flexWrap="wrap"
          padding={isMobile ? '0 0 20px' : ''}
        >
          <StyledLogo src="/logo-plain.jpg" alt="Logo AssureCompare" onClick={navigateHome} />
          <StyledTypography variant="h6">
            <em>
              Le premier comparateur d'assurance
              <br />
              <b>qui vous conseille dans vos choix !</b>
            </em>
          </StyledTypography>
          <Link href="tel:0184204480">
            <StyledFab id="call-cta-button" variant="extended">
              <PhoneForwardedIcon />
              <b>01 84 20 44 80</b>
            </StyledFab>
          </Link>
        </Box>
      </StyledColoredContainer>
      <StyledNavbarContainer>
        <Hidden smDown>
          <StyledButtonGroup variant="contained" color="primary">
            <StyledLink to={routes.formSante('')} muiButton>
              Assurance<strong>Santé</strong>
            </StyledLink>
            <StyledLink to={routes.formEmprunteur('')} muiButton>
              Assurance<strong>Prêt-Crédit</strong>
            </StyledLink>
            <StyledLink to={routes.formHabitation('')} muiButton>
              Assurance<strong>Habitation</strong>
            </StyledLink>
            <StyledLink to={routes.autoMoto()} muiButton>
              Assurance<strong>Auto-Moto</strong>
            </StyledLink>
            <StyledLink to={routes.formAnimaux('')} muiButton>
              Assurance<strong>Chien-Chats</strong>
            </StyledLink>
            <StyledLink to={routes.formVoyage('')} muiButton>
              Assurance<strong>Voyage</strong>
            </StyledLink>
            {/* <StyledLink to={routes.formMobile('')} muiButton>
              Assurance<strong>Mobile & Équipement</strong>
            </StyledLink> */}
            {/* <StyledLink to={routes.formMobile('')} muiButton>
              Assurance<strong>RC Professionnelle</strong>
            </StyledLink> */}
            <StyledLink to={routes.formSurcomplementaire()} muiButton>
              Assurance<strong>Surcomplémentaire</strong>
            </StyledLink>
            <StyledLink to={routes.autres()} muiButton>
              Autres<strong>assurances</strong>
            </StyledLink>
          </StyledButtonGroup>
        </Hidden>
      </StyledNavbarContainer>
    </>
  );
};

export default Header;
