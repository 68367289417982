import { all } from 'redux-saga/effects';

import loggedUserSaga from './LoggedUser/LoggedUser.saga';
import rcProQuoteSaga from './RCProQuote/RCProQuote.saga';
import surcomplementaireQuoteSaga from './SurcomplementaireQuote/SurcomplementaireQuote.saga';
import telesurveillanceQuoteSaga from './TelesurveillanceQuote/TelesurveillanceQuote.saga';
import autoTemporaireQuoteSaga from './AutoTemporaireQuote/AutoTemporaireQuote.saga';
import loyerImpayeQuoteSaga from './LoyerImpayeQuote/LoyerImpayeQuote.saga';
import sansPermisQuoteSaga from './SansPermisQuote/SansPermisQuote.saga';
import campingCarQuoteSaga from './CampingCarQuote/CampingCarQuote.saga';
import animauxQuoteSaga from './AnimauxQuote/AnimauxQuote.saga';
import mobileQuoteSaga from './MobileQuote/MobileQuote.saga';
import voyageQuoteSaga from './VoyageQuote/VoyageQuote.saga';
import habitationQuoteSaga from './HabitationQuote/HabitationQuote.saga';
import emprunteurQuoteSaga from './EmprunteurQuote/EmprunteurQuote.saga';
import santeQuoteSaga from './SanteQuote/SanteQuote.saga';
import autoQuoteSaga from './AutoQuote/AutoQuote.saga';
import motoQuoteSaga from './MotoQuote/MotoQuote.saga';
import partenaireSaga from './Partenaire/Partenaire.saga';

export default function* rootSaga() {
  yield all([
    loggedUserSaga(),
    rcProQuoteSaga(),
    surcomplementaireQuoteSaga(),
    telesurveillanceQuoteSaga(),
    autoTemporaireQuoteSaga(),
    loyerImpayeQuoteSaga(),
    sansPermisQuoteSaga(),
    campingCarQuoteSaga(),
    mobileQuoteSaga(),
    animauxQuoteSaga(),
    voyageQuoteSaga(),
    habitationQuoteSaga(),
    emprunteurQuoteSaga(),
    santeQuoteSaga(),
    autoQuoteSaga(),
    motoQuoteSaga(),
    partenaireSaga(),
  ]);
}
