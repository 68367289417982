import React from 'react';

import Container from '@mui/material/Container';
import { StyledColoredContainer, ColoredContainerProps } from './ColoredContainer.style';

export interface OwnProps extends ColoredContainerProps {
  className?: string;
  children: React.ReactNode | React.ReactNodeArray;
}

export interface Props extends OwnProps {}

const ColoredContainer = ({ color, textColor, className, children }: Props) => {
  return (
    <StyledColoredContainer color={color} textColor={textColor}>
      <Container className={className}>{children!}</Container>
    </StyledColoredContainer>
  );
};

export default ColoredContainer;
