import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './SanteQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitSanteQuote(action: ReturnType<typeof actions.submitSanteQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<SanteQuote> = yield call(
      request.post<FormSanteValues>('sante/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitSanteQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formSante(quote.id)));
  } catch (error: any) {
    yield put(actions.submitSanteQuoteError(error));
  }
}

function* fetchSanteQuote(action: ReturnType<typeof actions.fetchSanteQuote>) {
  console.log('fetchSanteQuote');
  try {
    const santeQuoteId = action.payload;
    console.log('santeQuoteId', santeQuoteId);

    const quote: WithId<SanteQuote> = yield call(firestore.get(`quotes/${santeQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchSanteQuotesSuccess([quote]));
  } catch (error: any) {
    console.log('error', error);
    yield put(push(routes.formSante('')));
    yield put(actions.fetchSanteQuoteError(error));
  }
}

function* updateSanteQuote(action: ReturnType<typeof actions.updateSanteQuote>) {
  try {
    yield put(actions.updateSanteQuoteSuccess(action.payload));
  } catch (error: any) {
    yield put(actions.updateSanteQuoteError(error));
  }
}

function* saveSanteQuote(action: ReturnType<typeof actions.saveSanteQuote>) {
  try {
    const { santeQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`sante/devis/${santeQuoteId}`, tarif));

    yield put(actions.saveSanteQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveSanteQuoteError(error));
  }
}

function* subscribeSanteQuote(action: ReturnType<typeof actions.subscribeSanteQuote>) {
  try {
    const { santeQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`sante/souscription/${santeQuoteId}`, tarif));

    yield put(actions.subscribeSanteQuoteSuccess());
  } catch (error: any) {
    yield put(actions.subscribeSanteQuoteError(error));
  }
}

export default function* santeQuoteSaga() {
  yield takeEvery(actions.submitSanteQuote, submitSanteQuote);
  yield takeEvery(actions.fetchSanteQuote, fetchSanteQuote);
  yield takeEvery(actions.updateSanteQuote, updateSanteQuote);
  yield takeEvery(actions.saveSanteQuote, saveSanteQuote);
  yield takeEvery(actions.subscribeSanteQuote, subscribeSanteQuote);
}
