import styled from '@emotion/styled';

import Container from '@mui/material/Container';

export const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
