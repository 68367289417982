import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './AutoQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitAutoQuote(action: ReturnType<typeof actions.submitAutoQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<AutoQuote> = yield call(
      request.post<FormAutoValues>('auto/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitAutoQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formAuto(quote.id)));
  } catch (error: any) {
    yield put(actions.submitAutoQuoteError(error));
  }
}

function* fetchAutoQuote(action: ReturnType<typeof actions.fetchAutoQuote>) {
  try {
    const autoQuoteId = action.payload;

    const quote: WithId<AutoQuote> = yield call(firestore.get(`quotes/${autoQuoteId}`));

    yield put(actions.fetchAutoQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchAutoQuoteError(error));
  }
}

function* updateAutoQuote(action: ReturnType<typeof actions.updateAutoQuote>) {
  try {
    const autoQuote = action.payload;

    yield put(actions.updateAutoQuoteSuccess(autoQuote));
  } catch (error: any) {
    yield put(actions.updateAutoQuoteError(error));
  }
}

function* saveAutoQuote(action: ReturnType<typeof actions.saveAutoQuote>) {
  try {
    const { autoQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`auto/devis/${autoQuoteId}`, tarif));

    yield put(actions.saveAutoQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveAutoQuoteError(error));
  }
}

export default function* autoQuoteSaga() {
  yield takeEvery(actions.submitAutoQuote, submitAutoQuote);
  yield takeEvery(actions.fetchAutoQuote, fetchAutoQuote);
  yield takeEvery(actions.updateAutoQuote, updateAutoQuote);
  yield takeEvery(actions.saveAutoQuote, saveAutoQuote);
}
