import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './RCProQuote.actions';

const initialState: RCProQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<RCProQuoteState>(initialState, {
  [actions.submitRCProQuote.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.submitRCProQuote>,
  ): RCProQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitRCProQuoteError.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.submitRCProQuoteError>,
  ): RCProQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitRCProQuoteSuccess.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.submitRCProQuoteSuccess>,
  ): RCProQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchRCProQuote.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.fetchRCProQuote>,
  ): RCProQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchRCProQuoteError.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.fetchRCProQuoteError>,
  ): RCProQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchRCProQuotesSuccess.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.fetchRCProQuotesSuccess>,
  ): RCProQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<RCProQuote> }>(
      (acc, rcProQuote) => ({
        ...acc,
        [rcProQuote.id]: rcProQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((rcProQuote) => rcProQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateRCProQuote.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.updateRCProQuote>,
  ): RCProQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateRCProQuoteError.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.updateRCProQuoteError>,
  ): RCProQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateRCProQuoteSuccess.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.updateRCProQuoteSuccess>,
  ): RCProQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveRCProQuote.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.saveRCProQuote>,
  ): RCProQuoteState => ({
    ...state,
    isUpdating: action.payload.rcProQuoteId,
  }),
  [actions.saveRCProQuoteError.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.saveRCProQuoteError>,
  ): RCProQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveRCProQuoteSuccess.type]: (
    state: RCProQuoteState,
    action: ReturnType<typeof actions.saveRCProQuoteSuccess>,
  ): RCProQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
