import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './CampingCarQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitCampingCarQuote(action: ReturnType<typeof actions.submitCampingCarQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<CampingCarQuote> = yield call(
      request.post<FormCampingCarValues>('campingCar/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitCampingCarQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formCampingCar(quote.id)));
  } catch (error: any) {
    yield put(actions.submitCampingCarQuoteError(error));
  }
}

function* fetchCampingCarQuote(action: ReturnType<typeof actions.fetchCampingCarQuote>) {
  try {
    const campingCarQuoteId = action.payload;

    const quote: WithId<CampingCarQuote> = yield call(firestore.get(`quotes/${campingCarQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchCampingCarQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchCampingCarQuoteError(error));
  }
}

function* updateCampingCarQuote(action: ReturnType<typeof actions.updateCampingCarQuote>) {
  try {
    const campingCarQuote = action.payload;

    yield put(actions.updateCampingCarQuoteSuccess(campingCarQuote));
  } catch (error: any) {
    yield put(actions.updateCampingCarQuoteError(error));
  }
}

function* saveCampingCarQuote(action: ReturnType<typeof actions.saveCampingCarQuote>) {
  try {
    const { campingCarQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`campingCar/devis/${campingCarQuoteId}`, tarif));

    yield put(actions.saveCampingCarQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveCampingCarQuoteError(error));
  }
}

export default function* campingCarQuoteSaga() {
  yield takeEvery(actions.submitCampingCarQuote, submitCampingCarQuote);
  yield takeEvery(actions.fetchCampingCarQuote, fetchCampingCarQuote);
  yield takeEvery(actions.updateCampingCarQuote, updateCampingCarQuote);
  yield takeEvery(actions.saveCampingCarQuote, saveCampingCarQuote);
}
