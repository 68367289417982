import React from 'react';
import { RecaptchaProps } from 'react-recaptcha';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { StyledRecaptcha } from './Recaptcha.style';

export interface OwnProps extends Omit<RecaptchaProps, 'verifyCallback' | 'sitekey'> {
  onSuccess: (token: string) => void;
  error?: boolean;
}

export interface Props extends OwnProps {}

const Recaptcha = ({ onSuccess, error, ...props }: Props) => (
  <FormControl error={!!error}>
    <StyledRecaptcha
      {...props}
      verifyCallback={onSuccess}
      sitekey="6LfwKeMZAAAAAKNAzkSr1nHiac-fIcZNJvpfsMXU"
    />
    {!!error && <FormHelperText>Veuillez cocher la case ci-dessus</FormHelperText>}
  </FormControl>
);

export default Recaptcha;
