import { createAction } from '@reduxjs/toolkit';

export const submitRCProQuote = createAction<FormRCProValues>('CREATE_RC_PRO_QUOTE_REQUEST');
export const submitRCProQuoteSuccess = createAction<WithId<RCProQuote>>(
  'CREATE_RC_PRO_QUOTE_SUCCESS',
);
export const submitRCProQuoteError = createAction<Error>('CREATE_RC_PRO_QUOTE_ERROR');

export const fetchRCProQuote = createAction<string>('FETCH_RC_PRO_QUOTE_REQUEST');
export const fetchRCProQuoteError = createAction<Error>('FETCH_RC_PRO_QUOTE_ERROR');
export const fetchRCProQuotesSuccess = createAction<WithId<RCProQuote>[]>(
  'FETCH_RC_PRO_QUOTES_SUCCESS',
);

export const updateRCProQuote = createAction<WithId<RCProQuote>>('UPDATE_RC_PRO_QUOTE_REQUEST');
export const updateRCProQuoteSuccess = createAction<WithId<RCProQuote>>(
  'UPDATE_RC_PRO_QUOTE_SUCCESS',
);
export const updateRCProQuoteError = createAction<Error>('UPDATE_RC_PRO_QUOTE_ERROR');

export const saveRCProQuote = createAction(
  'SAVE_RC_PRO_QUOTE_REQUEST',
  (rcProQuoteId: string, tarif: Tarif) => ({
    payload: { rcProQuoteId, tarif },
  }),
);
export const saveRCProQuoteSuccess = createAction('SAVE_RC_PRO_QUOTE_SUCCESS');
export const saveRCProQuoteError = createAction<Error>('SAVE_RC_PRO_QUOTE_ERROR');
