import { getAuth, GoogleAuthProvider, connectAuthEmulator } from 'firebase/auth';

import routes from 'App.routes';
import { isLocalhost } from 'services/navigation';

import { firebaseApp } from '.';

export const auth = getAuth(firebaseApp);

const authUrl = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL;
console.log('authUrl', authUrl);
if (isLocalhost && authUrl) connectAuthEmulator(auth, authUrl, { disableWarnings: true });
auth.useDeviceLanguage();

export const firebaseUIConfig = {
  // Redirect flow instead of popup because popup not working on iOS standalone / Messenger browser
  signInFlow: 'redirect',
  // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: routes.home(),
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};
