import { createAction } from '@reduxjs/toolkit';

export const submitMotoQuote = createAction<FormMotoValues>('CREATE_MOTO_QUOTE_REQUEST');
export const submitMotoQuoteSuccess = createAction<WithId<MotoQuote>>('CREATE_MOTO_QUOTE_SUCCESS');
export const submitMotoQuoteError = createAction<Error>('CREATE_MOTO_QUOTE_ERROR');

export const fetchMotoQuote = createAction<string>('FETCH_MOTO_QUOTE_REQUEST');
export const fetchMotoQuoteError = createAction<Error>('FETCH_MOTO_QUOTE_ERROR');
export const fetchMotoQuotesSuccess = createAction<WithId<MotoQuote>[]>(
  'FETCH_MOTO_QUOTES_SUCCESS',
);

export const updateMotoQuote = createAction<WithId<MotoQuote>>('UPDATE_MOTO_QUOTE_REQUEST');
export const updateMotoQuoteSuccess = createAction<WithId<MotoQuote>>('UPDATE_MOTO_QUOTE_SUCCESS');
export const updateMotoQuoteError = createAction<Error>('UPDATE_MOTO_QUOTE_ERROR');

export const saveMotoQuote = createAction(
  'SAVE_MOTO_QUOTE_REQUEST',
  (motoQuoteId: string, tarif: Tarif) => ({ payload: { motoQuoteId, tarif } }),
);
export const saveMotoQuoteSuccess = createAction('SAVE_MOTO_QUOTE_SUCCESS');
export const saveMotoQuoteError = createAction<Error>('SAVE_MOTO_QUOTE_ERROR');
