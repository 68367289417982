import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './AnimauxQuote.actions';

const initialState: AnimauxQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<AnimauxQuoteState>(initialState, {
  [actions.submitAnimauxQuote.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.submitAnimauxQuote>,
  ): AnimauxQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitAnimauxQuoteError.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.submitAnimauxQuoteError>,
  ): AnimauxQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitAnimauxQuoteSuccess.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.submitAnimauxQuoteSuccess>,
  ): AnimauxQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchAnimauxQuote.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.fetchAnimauxQuote>,
  ): AnimauxQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchAnimauxQuoteError.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.fetchAnimauxQuoteError>,
  ): AnimauxQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchAnimauxQuotesSuccess.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.fetchAnimauxQuotesSuccess>,
  ): AnimauxQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<AnimauxQuote> }>(
      (acc, animauxQuote) => ({
        ...acc,
        [animauxQuote.id]: animauxQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((animauxQuote) => animauxQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateAnimauxQuote.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.updateAnimauxQuote>,
  ): AnimauxQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateAnimauxQuoteError.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.updateAnimauxQuoteError>,
  ): AnimauxQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateAnimauxQuoteSuccess.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.updateAnimauxQuoteSuccess>,
  ): AnimauxQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveAnimauxQuote.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.saveAnimauxQuote>,
  ): AnimauxQuoteState => ({
    ...state,
    isUpdating: action.payload.animauxQuoteId,
  }),
  [actions.saveAnimauxQuoteError.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.saveAnimauxQuoteError>,
  ): AnimauxQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveAnimauxQuoteSuccess.type]: (
    state: AnimauxQuoteState,
    action: ReturnType<typeof actions.saveAnimauxQuoteSuccess>,
  ): AnimauxQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
