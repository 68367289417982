import routes from 'App.routes';
import moment from 'moment';
import React from 'react';

import {
  StyledColoredFooter,
  StyledColoredContainer,
  StyledFlex,
  StyledList,
  StyledListHeader,
  StyledLink,
  StyledLogo,
} from './Footer.style';

export interface OwnProps {}

export interface Props extends OwnProps {}

const Footer = (props: Props) => (
  <>
    <StyledColoredFooter>
      <div>
        <StyledListHeader>Nos comparateurs</StyledListHeader>
        <StyledList>
          <StyledLink to={routes.formSante('')}>Assurance Santé</StyledLink>
          <StyledLink to={routes.formEmprunteur('')}>Assurance Crédit</StyledLink>
          <StyledLink to={routes.formHabitation('')}>Assurance Habitation</StyledLink>
          <StyledLink to={routes.formAnimaux('')}>Assurance Chien-chats</StyledLink>
          <StyledLink to={routes.formAuto('')}>Assurance Auto</StyledLink>
          <StyledLink to={routes.formMoto('')}>Assurance Moto</StyledLink>
          <StyledLink to={routes.formSansPermis('')}>Assurance Sans Permis</StyledLink>
          <StyledLink to={routes.formAutoTemporaire('')}>Assurance Auto Temporaire</StyledLink>
          <StyledLink to={routes.formVoyage('')}>Assurance Voyage</StyledLink>
          <StyledLink to={routes.formLoyerImpaye('')}>Assurance Loyer Impayé</StyledLink>
          <StyledLink to={routes.formTelesurveillance('')}>Assurance Télésurveillance</StyledLink>
          <StyledLink to={routes.formCampingCar('')}>Assurance Camping-Car</StyledLink>
          <StyledLink to={routes.formSurcomplementaire('')}>Assurance Surcomplémentaire</StyledLink>
        </StyledList>
      </div>
      <div>
        <StyledLogo src="/logo_footer.png" alt="Logo AssureCompare" />
      </div>
    </StyledColoredFooter>
    <StyledColoredContainer color="darkPrimary" textColor="grey">
      <StyledFlex>
        <StyledLink to={routes.contact()}>Contact</StyledLink>
        <StyledLink to={routes.partenaires()}>Partenaires</StyledLink>
        <StyledLink to={routes.cgu()}>Conditions générales d'utilisation</StyledLink>
        <StyledLink to={routes.mentionsLegales()}>Mentions légales</StyledLink>
        <StyledLink to={routes.espaceClient()}>Espace Client</StyledLink>
      </StyledFlex>
      <span>© {moment().format('YYYY')} AssureCompare</span>
    </StyledColoredContainer>
  </>
);

export default Footer;
