import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './MobileQuote.actions';

const initialState: MobileQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<MobileQuoteState>(initialState, {
  [actions.submitMobileQuote.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.submitMobileQuote>,
  ): MobileQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitMobileQuoteError.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.submitMobileQuoteError>,
  ): MobileQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitMobileQuoteSuccess.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.submitMobileQuoteSuccess>,
  ): MobileQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchMobileQuote.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.fetchMobileQuote>,
  ): MobileQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchMobileQuoteError.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.fetchMobileQuoteError>,
  ): MobileQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchMobileQuotesSuccess.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.fetchMobileQuotesSuccess>,
  ): MobileQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<MobileQuote> }>(
      (acc, mobileQuote) => ({
        ...acc,
        [mobileQuote.id]: mobileQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((mobileQuote) => mobileQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateMobileQuote.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.updateMobileQuote>,
  ): MobileQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateMobileQuoteError.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.updateMobileQuoteError>,
  ): MobileQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateMobileQuoteSuccess.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.updateMobileQuoteSuccess>,
  ): MobileQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveMobileQuote.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.saveMobileQuote>,
  ): MobileQuoteState => ({
    ...state,
    isUpdating: action.payload.mobileQuoteId,
  }),
  [actions.saveMobileQuoteError.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.saveMobileQuoteError>,
  ): MobileQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveMobileQuoteSuccess.type]: (
    state: MobileQuoteState,
    action: ReturnType<typeof actions.saveMobileQuoteSuccess>,
  ): MobileQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
