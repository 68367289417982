export const quoteTypeKey: { [key in QuoteType]: string } = {
  Santé: 'sante',
  Habitation: 'habitation',
  Auto: 'auto',
  'Deux-Roues': 'moto',
  Voyage: 'voyage',
  Animaux: 'animaux',
  'Mobile & Équipment': 'mobile',
  'Prêt-Crédit': 'credit',
  'Camping-Car': 'camping-car',
  'Loyer Impayé': 'loyer-impaye',
  'Sans Permis': 'sans-permis',
  'Auto Temporaire': 'auto-temporaire',
  Télésurveillance: 'telesurveillance',
  Surcomplémentaire: 'surcomplementaire',
  'RC Professionnelle': 'rc-pro',
};

const routes = {
  home: () => '/',
  autoMoto: () => `/assurance-auto-moto`,
  autres: () => '/autres-assurances',
  contact: () => '/contact',
  cgu: () => '/cgu',
  partenaires: () => '/partenaires',
  login: () => '/login',
  admin: () => '/admin',
  mentionsLegales: () => '/mentions-legales',
  espaceClient: () => '/espace-client',
  formSante: (santeQuoteId = ':santeQuoteId?') =>
    `/assurance-${quoteTypeKey['Santé']}/${santeQuoteId}`,
  formEmprunteur: (emprunteurQuoteId = ':emprunteurQuoteId?') =>
    `/assurance-${quoteTypeKey['Prêt-Crédit']}/${emprunteurQuoteId}`,
  formHabitation: (habitationQuoteId = ':habitationQuoteId?') =>
    `/assurance-${quoteTypeKey['Habitation']}/${habitationQuoteId}`,
  formAuto: (autoQuoteId = ':autoQuoteId?') => `/assurance-${quoteTypeKey['Auto']}/${autoQuoteId}`,
  formMoto: (motoQuoteId = ':motoQuoteId?') =>
    `/assurance-${quoteTypeKey['Deux-Roues']}/${motoQuoteId}`,
  formSansPermis: (sansPermisQuoteId = ':sansPermisQuoteId?') =>
    `/assurance-${quoteTypeKey['Sans Permis']}/${sansPermisQuoteId}`,
  formAutoTemporaire: (autoTemporaireQuoteId = ':autoTemporaireQuoteId?') =>
    `/assurance-${quoteTypeKey['Auto Temporaire']}/${autoTemporaireQuoteId}`,
  formAnimaux: (animauxQuoteId = ':animauxQuoteId?') =>
    `/assurance-${quoteTypeKey['Animaux']}/${animauxQuoteId}`,
  formMobile: (mobileQuoteId = ':mobileQuoteId?') =>
    `/assurance-${quoteTypeKey['Mobile & Équipment']}/${mobileQuoteId}`,
  formVoyage: (voyageQuoteId = ':voyageQuoteId?') =>
    `/assurance-${quoteTypeKey['Voyage']}/${voyageQuoteId}`,
  formLoyerImpaye: (loyerImpayeQuoteId = ':loyerImpayeQuoteId?') =>
    `/assurance-${quoteTypeKey['Loyer Impayé']}/${loyerImpayeQuoteId}`,
  formCampingCar: (campingCarQuoteId = ':campingCarQuoteId?') =>
    `/assurance-${quoteTypeKey['Camping-Car']}/${campingCarQuoteId}`,
  formTelesurveillance: (telesurveillanceQuoteId = ':telesurveillanceQuoteId?') =>
    `/assurance-${quoteTypeKey['Télésurveillance']}/${telesurveillanceQuoteId}`,
  formSurcomplementaire: (surcomplementaireQuoteId = ':surcomplementaireQuoteId?') =>
    `/assurance-${quoteTypeKey['Surcomplémentaire']}/${surcomplementaireQuoteId}`,
  formRCPro: (rcProQuoteId = ':rcProQuoteId?') =>
    `/assurance-${quoteTypeKey['RC Professionnelle']}/${rcProQuoteId}`,
};

export const quoteTypeResultsRoutes: { [key in QuoteType]: (quoteId?: string) => string } = {
  Santé: routes.formSante,
  Habitation: routes.formHabitation,
  Auto: routes.formAuto,
  'Deux-Roues': routes.formMoto,
  Voyage: routes.formVoyage,
  Animaux: routes.formAnimaux,
  'Mobile & Équipment': routes.formMobile,
  'Prêt-Crédit': routes.formEmprunteur,
  'Camping-Car': routes.formCampingCar,
  'Loyer Impayé': routes.formLoyerImpaye,
  'Sans Permis': routes.formSansPermis,
  'Auto Temporaire': routes.formAutoTemporaire,
  Télésurveillance: routes.formTelesurveillance,
  Surcomplémentaire: routes.formSurcomplementaire,
  'RC Professionnelle': routes.formRCPro,
};

export default routes;
