import React from 'react';

import CardActionArea from '@mui/material/CardActionArea';
import {
  StyledCard,
  StyledCardMedia,
  StyledCardContent,
  StyledAssuranceTypography,
  StyledNameTypography,
  StyledLink,
} from './AssuranceCard.style';

import { useNavigate } from 'services/navigation';

export interface OwnProps {
  header?: string;
  name: string;
  imgUrl: string;
  url: string;
}

export interface Props extends OwnProps {}

const AssuranceCard = ({ header = 'Assurance', name, imgUrl, url }: Props) => {
  const navigateUrl = useNavigate(url);

  return (
    <StyledCard>
      <CardActionArea onClick={navigateUrl}>
        <StyledCardContent>
          <StyledAssuranceTypography variant="body2" align="center">
            {header}
          </StyledAssuranceTypography>
          <StyledNameTypography variant="h6" align="center">
            {name}
          </StyledNameTypography>
        </StyledCardContent>
        <StyledCardMedia image={imgUrl} title={`Assurance ${name}`} />
        <StyledLink to={url} muiButton>
          Comparer
        </StyledLink>
      </CardActionArea>
    </StyledCard>
  );
};

export default AssuranceCard;
