import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './SurcomplementaireQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitSurcomplementaireQuote(
  action: ReturnType<typeof actions.submitSurcomplementaireQuote>,
) {
  try {
    const formValues = action.payload;

    const quote: WithId<SurcomplementaireQuote> = yield call(
      request.post<FormSurcomplementaireValues>('surcomplementaire/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitSurcomplementaireQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formSurcomplementaire(quote.id)));
  } catch (error: any) {
    yield put(actions.submitSurcomplementaireQuoteError(error));
  }
}

function* fetchSurcomplementaireQuote(
  action: ReturnType<typeof actions.fetchSurcomplementaireQuote>,
) {
  try {
    const surcomplementaireQuoteId = action.payload;

    const quote: WithId<SurcomplementaireQuote> = yield call(
      firestore.get(`quotes/${surcomplementaireQuoteId}`),
    );
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchSurcomplementaireQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchSurcomplementaireQuoteError(error));
  }
}

function* updateSurcomplementaireQuote(
  action: ReturnType<typeof actions.updateSurcomplementaireQuote>,
) {
  try {
    const surcomplementaireQuote = action.payload;

    yield put(actions.updateSurcomplementaireQuoteSuccess(surcomplementaireQuote));
  } catch (error: any) {
    yield put(actions.updateSurcomplementaireQuoteError(error));
  }
}

function* saveSurcomplementaireQuote(
  action: ReturnType<typeof actions.saveSurcomplementaireQuote>,
) {
  try {
    const { surcomplementaireQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`surcomplementaire/devis/${surcomplementaireQuoteId}`, tarif));

    yield put(actions.saveSurcomplementaireQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveSurcomplementaireQuoteError(error));
  }
}

export default function* surcomplementaireQuoteSaga() {
  yield takeEvery(actions.submitSurcomplementaireQuote, submitSurcomplementaireQuote);
  yield takeEvery(actions.fetchSurcomplementaireQuote, fetchSurcomplementaireQuote);
  yield takeEvery(actions.updateSurcomplementaireQuote, updateSurcomplementaireQuote);
  yield takeEvery(actions.saveSurcomplementaireQuote, saveSurcomplementaireQuote);
}
