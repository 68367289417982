export const colors = {
  primary: '#1a3668',
  darkPrimary: '#0a1529',
  secondary: '#e9ede2',
  tertiary: '#f1c40f',
  lighterGrey: '#f0f0f0',
  lightGrey: '#ddd',
  grey: '#aaa',
  darkGrey: '#666',
  white: '#fff',
  black: '#000',
};
