import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './SurcomplementaireQuote.actions';

const initialState: SurcomplementaireQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<SurcomplementaireQuoteState>(initialState, {
  [actions.submitSurcomplementaireQuote.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.submitSurcomplementaireQuote>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitSurcomplementaireQuoteError.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.submitSurcomplementaireQuoteError>,
  ): SurcomplementaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitSurcomplementaireQuoteSuccess.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.submitSurcomplementaireQuoteSuccess>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchSurcomplementaireQuote.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.fetchSurcomplementaireQuote>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchSurcomplementaireQuoteError.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.fetchSurcomplementaireQuoteError>,
  ): SurcomplementaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchSurcomplementaireQuotesSuccess.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.fetchSurcomplementaireQuotesSuccess>,
  ): SurcomplementaireQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<SurcomplementaireQuote> }>(
      (acc, surcomplementaireQuote) => ({
        ...acc,
        [surcomplementaireQuote.id]: surcomplementaireQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((surcomplementaireQuote) => surcomplementaireQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateSurcomplementaireQuote.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.updateSurcomplementaireQuote>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateSurcomplementaireQuoteError.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.updateSurcomplementaireQuoteError>,
  ): SurcomplementaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateSurcomplementaireQuoteSuccess.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.updateSurcomplementaireQuoteSuccess>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveSurcomplementaireQuote.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.saveSurcomplementaireQuote>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    isUpdating: action.payload.surcomplementaireQuoteId,
  }),
  [actions.saveSurcomplementaireQuoteError.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.saveSurcomplementaireQuoteError>,
  ): SurcomplementaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveSurcomplementaireQuoteSuccess.type]: (
    state: SurcomplementaireQuoteState,
    action: ReturnType<typeof actions.saveSurcomplementaireQuoteSuccess>,
  ): SurcomplementaireQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
