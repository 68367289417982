import React from 'react';
import styled from '@emotion/styled';

import Box, { BoxProps } from '@mui/material/Box';

import { mobileMediaQuery, desktopMediaQuery } from 'services/theme';

interface Props extends BoxProps {
  fluid?: boolean;
}

const FormRow = styled(({ fluid, ...props }: Props) => <Box {...props} />)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;

  && > * {
    flex-grow: 1;
    margin: 10px 5px;
  }

  @media ${mobileMediaQuery} {
    flex-wrap: wrap;

    && > *:not(.MuiButtonBase-root) {
      width: 100%;
    }
  }

  @media ${desktopMediaQuery} {
    & > * {
      flex-basis: ${({ fluid, children }: Props) =>
        !fluid ? Math.round(100 / React.Children.count(children)) + '%' : 'auto'};
    }
  }
`;

export default FormRow;
