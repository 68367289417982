import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './VoyageQuote.actions';

const initialState: VoyageQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<VoyageQuoteState>(initialState, {
  [actions.submitVoyageQuote.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.submitVoyageQuote>,
  ): VoyageQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitVoyageQuoteError.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.submitVoyageQuoteError>,
  ): VoyageQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitVoyageQuoteSuccess.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.submitVoyageQuoteSuccess>,
  ): VoyageQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchVoyageQuote.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.fetchVoyageQuote>,
  ): VoyageQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchVoyageQuoteError.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.fetchVoyageQuoteError>,
  ): VoyageQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchVoyageQuotesSuccess.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.fetchVoyageQuotesSuccess>,
  ): VoyageQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<VoyageQuote> }>(
      (acc, voyageQuote) => ({
        ...acc,
        [voyageQuote.id]: voyageQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((voyageQuote) => voyageQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateVoyageQuote.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.updateVoyageQuote>,
  ): VoyageQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateVoyageQuoteError.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.updateVoyageQuoteError>,
  ): VoyageQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateVoyageQuoteSuccess.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.updateVoyageQuoteSuccess>,
  ): VoyageQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveVoyageQuote.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.saveVoyageQuote>,
  ): VoyageQuoteState => ({
    ...state,
    isUpdating: action.payload.voyageQuoteId,
  }),
  [actions.saveVoyageQuoteError.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.saveVoyageQuoteError>,
  ): VoyageQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveVoyageQuoteSuccess.type]: (
    state: VoyageQuoteState,
    action: ReturnType<typeof actions.saveVoyageQuoteSuccess>,
  ): VoyageQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
