import { createAction } from '@reduxjs/toolkit';

export const submitSurcomplementaireQuote = createAction<FormSurcomplementaireValues>(
  'CREATE_SURCOMPLEMENTAIRE_QUOTE_REQUEST',
);
export const submitSurcomplementaireQuoteSuccess = createAction<WithId<SurcomplementaireQuote>>(
  'CREATE_SURCOMPLEMENTAIRE_QUOTE_SUCCESS',
);
export const submitSurcomplementaireQuoteError = createAction<Error>(
  'CREATE_SURCOMPLEMENTAIRE_QUOTE_ERROR',
);

export const fetchSurcomplementaireQuote = createAction<string>(
  'FETCH_SURCOMPLEMENTAIRE_QUOTE_REQUEST',
);
export const fetchSurcomplementaireQuoteError = createAction<Error>(
  'FETCH_SURCOMPLEMENTAIRE_QUOTE_ERROR',
);
export const fetchSurcomplementaireQuotesSuccess = createAction<WithId<SurcomplementaireQuote>[]>(
  'FETCH_SURCOMPLEMENTAIRE_QUOTES_SUCCESS',
);

export const updateSurcomplementaireQuote = createAction<WithId<SurcomplementaireQuote>>(
  'UPDATE_SURCOMPLEMENTAIRE_QUOTE_REQUEST',
);
export const updateSurcomplementaireQuoteSuccess = createAction<WithId<SurcomplementaireQuote>>(
  'UPDATE_SURCOMPLEMENTAIRE_QUOTE_SUCCESS',
);
export const updateSurcomplementaireQuoteError = createAction<Error>(
  'UPDATE_SURCOMPLEMENTAIRE_QUOTE_ERROR',
);

export const saveSurcomplementaireQuote = createAction(
  'SAVE_SURCOMPLEMENTAIRE_QUOTE_REQUEST',
  (surcomplementaireQuoteId: string, tarif: Tarif) => ({
    payload: { surcomplementaireQuoteId, tarif },
  }),
);
export const saveSurcomplementaireQuoteSuccess = createAction(
  'SAVE_SURCOMPLEMENTAIRE_QUOTE_SUCCESS',
);
export const saveSurcomplementaireQuoteError = createAction<Error>(
  'SAVE_SURCOMPLEMENTAIRE_QUOTE_ERROR',
);
