import styled from '@emotion/styled';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from 'components/Link';

import { colors } from 'services/style';

export const StyledCard = styled(Card)`
  flex: 1;
  min-width: 290px;
  margin: 5px;
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 160px;
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 15px 0 10px;
  border-bottom: 5px solid ${colors.tertiary};
`;

export const StyledAssuranceTypography = styled(Typography)`
  text-transform: uppercase;
  line-height: 1.6em;
`;

export const StyledNameTypography = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
  line-height: 0.95em;
`;

export const StyledLink = styled(Link)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-weight: bold;
  opacity: 0.92;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${colors.primary};
  color: ${colors.white};
  text-transform: uppercase;
  padding: 10px 0;
  text-align: center;
`;
