import React from 'react';
import _isEqual from 'lodash/isEqual';
import InputMask from 'react-input-mask';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FilledInputProps } from '@mui/material/FilledInput';
import { InputBaseProps } from '@mui/material/InputBase';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';

import { deepMemo } from 'services/hoc';
import { Form, useFormContext } from 'services/hooks';
import { handleEnterSubmit } from 'services/navigation';
import { parseNumber } from 'services/regex';

type InputProps = InputBaseProps | FilledInputProps | OutlinedInputProps;
type ValidationFunction = (value: string) => boolean | string;

export interface OwnProps
  extends Omit<
    TextFieldProps,
    | 'inputRef'
    | 'name'
    | 'error'
    | 'helperText'
    | 'InputProps'
    | 'value'
    | 'autoComplete'
    | 'onChange'
  > {
  mask?: string;
  integer?: boolean;
  fieldName: string;
  withError?: boolean;
  withSubmitShortcut?: boolean;
  rules?: Partial<{
    required: boolean | string;
    validate: ValidationFunction | { [key: string]: ValidationFunction };
    min: number | { value: number; message: string };
    max: number | { value: number; message: string };
  }>;
  InputProps?: InputProps | ((form: Form<any>) => InputProps);
  autocompleteProps?: {
    options: any[];
    loading?: boolean;
    filterOptions?: boolean;
    getOptionLabel?: (value: any) => string;
  };
}

export interface Props extends OwnProps {}

const InputField = deepMemo(
  ({
    type,
    integer = false,
    fieldName,
    mask,
    withError = true,
    withSubmitShortcut = true,
    variant = 'outlined',
    color = 'secondary',
    rules,
    InputProps,
    autocompleteProps,
    ...props
  }: Props) => {
    const fieldForm = useFormContext();
    if (!fieldForm) {
      console.error(
        `No form was found for input field ${fieldName}. Did you wrap it inside a FormContext.Provider?`,
      );
      return null;
    }

    if (typeof rules?.min !== 'undefined') {
      const min = typeof rules?.min === 'number' ? Number(rules?.min) : rules?.min.value;
      const message = typeof rules?.min === 'number' ? '' : rules?.min.message;
      rules.validate = {
        ...(typeof rules.validate === 'function' ? { validate: rules.validate } : rules.validate),
        min: (value) => parseNumber(value) > min || message,
      };
    }
    if (typeof rules?.max !== 'undefined') {
      const max = typeof rules?.max === 'number' ? Number(rules?.max) : rules?.max.value;
      const message = typeof rules?.max === 'number' ? '' : rules?.max.message;
      rules.validate = {
        ...(typeof rules.validate === 'function' ? { validate: rules.validate } : rules.validate),
        max: (value) => parseNumber(value) > max || message,
      };
    }

    if (!!props.select || !!autocompleteProps) {
      fieldForm.register(fieldName, { required: 'Veuillez saisir une valeur', ...rules });
    }

    const setValue = (newValue: any, inputName = fieldName) => {
      const transform = fieldForm.transforms[inputName];
      const listener = fieldForm.listeners[inputName];

      const newTransformedValue = !!transform ? transform(newValue) : newValue;

      if (!!listener) listener(newTransformedValue);
      fieldForm.setValue(inputName, newTransformedValue);

      if (!!props.select) fieldForm.trigger(inputName);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let newValue = event.target.value;
      if (type === 'number') {
        if (!!integer) {
          newValue = newValue.replace(/[^0-9]/g, '');
        } else {
          newValue = newValue.replace(/[^0-9,.]/g, '').replace(/\./g, ',');
          if (!/[0-9]+,?[0-9]*/g.test(newValue)) newValue = '';
        }
      }
      setValue(newValue, event.target.name);
    };

    const value = fieldForm.watch(fieldName);
    const disabled = !!props.disabled || !!fieldForm.disabled;
    const error = fieldName.split('.').reduce((obj, key) => obj?.[key], fieldForm.formState.errors);
    const handleSubmitShortcut =
      !!withSubmitShortcut && handleEnterSubmit(fieldForm.onSubmit, !!props.multiline);
    const baseTextFieldProps = {
      ...props,
      ...(type !== 'number' && { type }),
      variant,
      color,
      InputProps: typeof InputProps === 'function' ? InputProps(fieldForm) : InputProps,
      ...(!!withError && {
        error: !!error,
        helperText: error?.message,
      }),
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!!handleSubmitShortcut) handleSubmitShortcut(event);
        if (!!props.onKeyDown) props.onKeyDown(event);
      },
      disabled,
    };
    const textFieldProps = {
      ...baseTextFieldProps,
      name: fieldName,
      value,
      onChange,
      ...(!props.select && {
        inputRef: fieldForm.register(fieldName, {
          required: 'Veuillez saisir une valeur',
          ...rules,
        }),
      }),
    };

    const autocompleteFieldName = `${fieldName}_autocomplete`;
    const getOptionLabel = !!autocompleteProps?.getOptionLabel
      ? autocompleteProps?.getOptionLabel
      : (option: any) => option?.label || '';

    const InputElement = (inputProps?: any) =>
      !!autocompleteProps ? (
        <Autocomplete
          value={value}
          {...(disabled && {
            inputValue: fieldForm.watch(autocompleteFieldName), // to keep input value state
          })}
          onChange={(event, option: any) => setValue(option)}
          renderInput={(params) => (
            <TextField
              {...baseTextFieldProps}
              name={autocompleteFieldName}
              inputRef={fieldForm.register(autocompleteFieldName, {
                ...rules,
              })}
              onChange={onChange}
              {...params}
              {...inputProps}
            />
          )}
          disabled={disabled}
          options={autocompleteProps.options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={_isEqual}
          noOptionsText="Aucun résultat disponible"
          loadingText="Chargement..."
          clearText="Effacer"
          loading={autocompleteProps.loading}
          {...(typeof autocompleteProps.filterOptions !== 'undefined' &&
            !autocompleteProps.filterOptions && {
              filterOptions: () => autocompleteProps.options,
            })}
          autoComplete
          openOnFocus
        />
      ) : (
        <TextField {...textFieldProps} {...inputProps} />
      );

    if (!!mask) {
      return (
        <InputMask
          mask={mask}
          maskChar=""
          value={value}
          onChange={onChange}
          disabled={disabled}
          beforeMaskedValueChange={(newState) => ({
            ...newState,
            selection: {
              start: value.length,
              end: value.length,
              length: 0,
            },
          })}
        >
          {InputElement}
        </InputMask>
      );
    }

    return InputElement();
  },
);

export default InputField;
