import styled from '@emotion/styled';

import Container from '@mui/material/Container';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import ColoredContainer from 'components/ColoredContainer';
import Link from 'components/Link';

import { colors } from 'services/style';
import { mobileMediaQuery } from 'services/theme';

export const StyledColoredContainer = styled(ColoredContainer)`
  @media ${mobileMediaQuery} {
    padding: 0;
  }
`;

export const StyledLogo = styled.img`
  cursor: pointer;

  @media ${mobileMediaQuery} {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  padding: 12px 0;
  font-size: 1em;
  text-transform: uppercase;
  color: ${colors.black};
  background-color: ${colors.secondary};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1.25em;

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0.1;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;

  & > * {
    flex-grow: 1;
    border-color: transparent !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  text-transform: uppercase;
  line-height: 1.2em;
  font-size: 14px;
  padding: 20px 25px;
`;

export const StyledFab = styled(Fab)`
  & svg {
    margin-right: 10px;
  }
`;

export const StyledNavbarContainer = styled(Container)`
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 20px;
`;
