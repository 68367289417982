import styled from '@emotion/styled';

import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import InputField from 'components/InputField';
import { mobileMediaQuery } from 'services/theme';

export const StyledPaper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  margin: 30px 0;
`;

export const StyledInputField = styled(InputField)`
  margin: 10px;
`;

export const StyledFab = styled(Fab)`
  flex: 1;
  align-self: flex-end;
  margin-bottom: 30px;

  & svg {
    margin-left: 10px;
  }
`;

export const StyledIframe = styled.iframe`
  flex: 1;
  margin-left: 30px;

  @media ${mobileMediaQuery} {
    margin-left: 0;
    max-width: 100%;
  }
`;
