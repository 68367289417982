import {
  getStorage,
  ref,
  list,
  deleteObject,
  getDownloadURL,
  connectStorageEmulator,
} from 'firebase/storage';

import { isLocalhost } from 'services/navigation';

import { firebaseApp } from '.';

const storage = getStorage(firebaseApp);

const storagePort = process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT;
if (isLocalhost && storagePort) connectStorageEmulator(storage, 'localhost', Number(storagePort));

export const getAllUrls = (refPath: string) => () =>
  list(ref(storage, refPath)).then((result) =>
    Promise.all(result.items.map((item) => getDownloadURL(item))),
  );

export const getUrl = (refPath: string) => () => getDownloadURL(ref(storage, refPath));

export const remove = (refPath: string) => () => deleteObject(ref(storage, refPath));
