import React from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { store, persistor, history } from 'modules/store';
import theme from 'services/theme';

import AppLayout from 'pages/AppLayout';
import 'App.css';
import 'moment/locale/fr';
moment.locale('fr');

const App = () => {
  React.useEffect(() => history.listen(() => window.scrollTo(0, 0)), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <Helmet
                titleTemplate="%s – AssureCompare"
                defaultTitle="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
              >
                <meta
                  name="title"
                  content="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
                />
                <meta
                  name="description"
                  content="Le premier comparateur d'assurance qui vous conseille dans vos choix !"
                />
                <meta
                  property="og:title"
                  content="Comparateur d'assurance : le premier qui vous conseille dans vos choix - AssureCompare"
                />
                <meta
                  property="og:description"
                  content="Le premier comparateur d'assurance qui vous conseille dans vos choix !"
                />
              </Helmet>
              <CssBaseline />
              <AppLayout />
            </ConnectedRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
