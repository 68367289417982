import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import { isLocalhost } from 'services/navigation';

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const performance = getPerformance();

const appCheckToken = process.env.REACT_APP_FIREBASE_APP_CHECK_TOKEN;
if (isLocalhost && appCheckToken)
  // @ts-ignore
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckToken;

export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_PUBLIC_KEY ?? ''),
  isTokenAutoRefreshEnabled: isLocalhost,
});
