import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './VoyageQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitVoyageQuote(action: ReturnType<typeof actions.submitVoyageQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<VoyageQuote> = yield call(
      request.post<FormVoyageValues>('voyage/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitVoyageQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formVoyage(quote.id)));
  } catch (error: any) {
    yield put(actions.submitVoyageQuoteError(error));
  }
}

function* fetchVoyageQuote(action: ReturnType<typeof actions.fetchVoyageQuote>) {
  try {
    const voyageQuoteId = action.payload;

    const quote: WithId<VoyageQuote> = yield call(firestore.get(`quotes/${voyageQuoteId}`));
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchVoyageQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchVoyageQuoteError(error));
  }
}

function* updateVoyageQuote(action: ReturnType<typeof actions.updateVoyageQuote>) {
  try {
    const voyageQuote = action.payload;

    yield put(actions.updateVoyageQuoteSuccess(voyageQuote));
  } catch (error: any) {
    yield put(actions.updateVoyageQuoteError(error));
  }
}

function* saveVoyageQuote(action: ReturnType<typeof actions.saveVoyageQuote>) {
  try {
    const { voyageQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`voyage/devis/${voyageQuoteId}`, tarif));

    yield put(actions.saveVoyageQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveVoyageQuoteError(error));
  }
}

export default function* voyageQuoteSaga() {
  yield takeEvery(actions.submitVoyageQuote, submitVoyageQuote);
  yield takeEvery(actions.fetchVoyageQuote, fetchVoyageQuote);
  yield takeEvery(actions.updateVoyageQuote, updateVoyageQuote);
  yield takeEvery(actions.saveVoyageQuote, saveVoyageQuote);
}
