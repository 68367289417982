import React from 'react';

import { InputFieldProps } from 'components/InputField';
import { StyledInputField } from './MobileField.style';

export interface OwnProps extends Omit<InputFieldProps, 'type' | 'label' | 'mask' | 'rules'> {
  mobileOnly?: boolean;
}

export interface Props extends OwnProps {}

const MobileField = ({ mobileOnly = false, ...props }: Props) => (
  <StyledInputField
    {...props}
    label="Téléphone"
    mask="99 99 99 99 99"
    type="tel"
    rules={{
      required: 'Veuillez saisir votre numéro de téléphone',
      validate: (value) => {
        if (!!mobileOnly && !value.startsWith('06') && !value.startsWith('07')) {
          return 'Veuillez saisir un numéro de téléphone mobile';
        }

        return value.length === 14 || 'Veuillez saisir un numéro de téléphone valide';
      },
    }}
  />
);

export default MobileField;
