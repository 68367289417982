import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './MotoQuote.actions';

const initialState: MotoQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<MotoQuoteState>(initialState, {
  [actions.submitMotoQuote.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.submitMotoQuote>,
  ): MotoQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitMotoQuoteError.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.submitMotoQuoteError>,
  ): MotoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitMotoQuoteSuccess.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.submitMotoQuoteSuccess>,
  ): MotoQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchMotoQuote.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.fetchMotoQuote>,
  ): MotoQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchMotoQuoteError.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.fetchMotoQuoteError>,
  ): MotoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchMotoQuotesSuccess.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.fetchMotoQuotesSuccess>,
  ): MotoQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<MotoQuote> }>(
      (acc, motoQuote) => ({
        ...acc,
        [motoQuote.id]: motoQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((motoQuote) => motoQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateMotoQuote.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.updateMotoQuote>,
  ): MotoQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateMotoQuoteError.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.updateMotoQuoteError>,
  ): MotoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateMotoQuoteSuccess.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.updateMotoQuoteSuccess>,
  ): MotoQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveMotoQuote.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.saveMotoQuote>,
  ): MotoQuoteState => ({
    ...state,
    isUpdating: action.payload.motoQuoteId,
  }),
  [actions.saveMotoQuoteError.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.saveMotoQuoteError>,
  ): MotoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveMotoQuoteSuccess.type]: (
    state: MotoQuoteState,
    action: ReturnType<typeof actions.saveMotoQuoteSuccess>,
  ): MotoQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
