import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './AutoTemporaireQuote.actions';

const initialState: AutoTemporaireQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<AutoTemporaireQuoteState>(initialState, {
  [actions.submitAutoTemporaireQuote.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.submitAutoTemporaireQuote>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitAutoTemporaireQuoteError.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.submitAutoTemporaireQuoteError>,
  ): AutoTemporaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitAutoTemporaireQuoteSuccess.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.submitAutoTemporaireQuoteSuccess>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchAutoTemporaireQuote.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.fetchAutoTemporaireQuote>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchAutoTemporaireQuoteError.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.fetchAutoTemporaireQuoteError>,
  ): AutoTemporaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchAutoTemporaireQuotesSuccess.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.fetchAutoTemporaireQuotesSuccess>,
  ): AutoTemporaireQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<AutoTemporaireQuote> }>(
      (acc, autoTemporaireQuote) => ({
        ...acc,
        [autoTemporaireQuote.id]: autoTemporaireQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((autoTemporaireQuote) => autoTemporaireQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateAutoTemporaireQuote.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.updateAutoTemporaireQuote>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateAutoTemporaireQuoteError.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.updateAutoTemporaireQuoteError>,
  ): AutoTemporaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateAutoTemporaireQuoteSuccess.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.updateAutoTemporaireQuoteSuccess>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveAutoTemporaireQuote.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.saveAutoTemporaireQuote>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    isUpdating: action.payload.autoTemporaireQuoteId,
  }),
  [actions.saveAutoTemporaireQuoteError.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.saveAutoTemporaireQuoteError>,
  ): AutoTemporaireQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveAutoTemporaireQuoteSuccess.type]: (
    state: AutoTemporaireQuoteState,
    action: ReturnType<typeof actions.saveAutoTemporaireQuoteSuccess>,
  ): AutoTemporaireQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
