import { push } from 'connected-react-router';
import { delay, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './AutoTemporaireQuote.actions';

import * as firestore from 'modules/firebase/firestore';
import { logEvent } from 'modules/firebase/analytics';
import routes from 'App.routes';

import * as request from 'services/networking';

function* submitAutoTemporaireQuote(action: ReturnType<typeof actions.submitAutoTemporaireQuote>) {
  try {
    const formValues = action.payload;

    const quote: WithId<AutoTemporaireQuote> = yield call(
      request.post<FormAutoTemporaireValues>('autoTemporaire/tarifs', formValues),
    );
    logEvent('Demande_de_devis_succes');

    yield put(actions.submitAutoTemporaireQuoteSuccess(quote));
    yield delay(400);
    yield put(push(routes.formAutoTemporaire(quote.id)));
  } catch (error: any) {
    yield put(actions.submitAutoTemporaireQuoteError(error));
  }
}

function* fetchAutoTemporaireQuote(action: ReturnType<typeof actions.fetchAutoTemporaireQuote>) {
  try {
    const autoTemporaireQuoteId = action.payload;

    const quote: WithId<AutoTemporaireQuote> = yield call(
      firestore.get(`quotes/${autoTemporaireQuoteId}`),
    );
    if (!quote) throw new Error("Ce devis n'existe pas");

    yield put(actions.fetchAutoTemporaireQuotesSuccess([quote]));
  } catch (error: any) {
    yield put(actions.fetchAutoTemporaireQuoteError(error));
  }
}

function* updateAutoTemporaireQuote(action: ReturnType<typeof actions.updateAutoTemporaireQuote>) {
  try {
    const autoTemporaireQuote = action.payload;

    yield put(actions.updateAutoTemporaireQuoteSuccess(autoTemporaireQuote));
  } catch (error: any) {
    yield put(actions.updateAutoTemporaireQuoteError(error));
  }
}

function* saveAutoTemporaireQuote(action: ReturnType<typeof actions.saveAutoTemporaireQuote>) {
  try {
    const { autoTemporaireQuoteId, tarif } = action.payload;
    yield call(request.post<{}>(`autoTemporaire/devis/${autoTemporaireQuoteId}`, tarif));

    yield put(actions.saveAutoTemporaireQuoteSuccess());
  } catch (error: any) {
    yield put(actions.saveAutoTemporaireQuoteError(error));
  }
}

export default function* autoTemporaireQuoteSaga() {
  yield takeEvery(actions.submitAutoTemporaireQuote, submitAutoTemporaireQuote);
  yield takeEvery(actions.fetchAutoTemporaireQuote, fetchAutoTemporaireQuote);
  yield takeEvery(actions.updateAutoTemporaireQuote, updateAutoTemporaireQuote);
  yield takeEvery(actions.saveAutoTemporaireQuote, saveAutoTemporaireQuote);
}
