import _union from 'lodash/union';
import { createReducer } from '@reduxjs/toolkit';

import * as actions from './AutoQuote.actions';

const initialState: AutoQuoteState = {
  allIds: [],
  byIds: {},
  isCreating: false,
  isFetching: [],
  isUpdating: '',
  isDeleting: '',
};

export default createReducer<AutoQuoteState>(initialState, {
  [actions.submitAutoQuote.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.submitAutoQuote>,
  ): AutoQuoteState => ({
    ...state,
    isCreating: true,
  }),
  [actions.submitAutoQuoteError.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.submitAutoQuoteError>,
  ): AutoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isCreating: false,
    };
  },
  [actions.submitAutoQuoteSuccess.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.submitAutoQuoteSuccess>,
  ): AutoQuoteState => ({
    ...state,
    allIds: [...state.allIds, action.payload.id],
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isCreating: false,
  }),
  [actions.fetchAutoQuote.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.fetchAutoQuote>,
  ): AutoQuoteState => ({
    ...state,
    isFetching: [action.payload],
  }),
  [actions.fetchAutoQuoteError.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.fetchAutoQuoteError>,
  ): AutoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isFetching: [],
    };
  },
  [actions.fetchAutoQuotesSuccess.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.fetchAutoQuotesSuccess>,
  ): AutoQuoteState => {
    const newByIds = action.payload.reduce<{ [id: string]: WithId<AutoQuote> }>(
      (acc, autoQuote) => ({
        ...acc,
        [autoQuote.id]: autoQuote,
      }),
      {},
    );

    return {
      ...state,
      allIds: _union(
        state.allIds,
        action.payload.map((autoQuote) => autoQuote.id),
      ),
      byIds: {
        ...state.byIds,
        ...newByIds,
      },
      isFetching: [],
    };
  },
  [actions.updateAutoQuote.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.updateAutoQuote>,
  ): AutoQuoteState => ({
    ...state,
    isUpdating: action.payload.id,
  }),
  [actions.updateAutoQuoteError.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.updateAutoQuoteError>,
  ): AutoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.updateAutoQuoteSuccess.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.updateAutoQuoteSuccess>,
  ): AutoQuoteState => ({
    ...state,
    allIds: _union(state.allIds, [action.payload.id]),
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload,
    },
    isUpdating: '',
  }),
  [actions.saveAutoQuote.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.saveAutoQuote>,
  ): AutoQuoteState => ({
    ...state,
    isUpdating: action.payload.autoQuoteId,
  }),
  [actions.saveAutoQuoteError.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.saveAutoQuoteError>,
  ): AutoQuoteState => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(action.payload);
    }

    return {
      ...state,
      isUpdating: '',
    };
  },
  [actions.saveAutoQuoteSuccess.type]: (
    state: AutoQuoteState,
    action: ReturnType<typeof actions.saveAutoQuoteSuccess>,
  ): AutoQuoteState => ({
    ...state,
    isUpdating: '',
  }),
});
